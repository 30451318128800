import React, { Fragment } from 'react'
import { TransitionGroup, Transition } from 'react-transition-group'

import { useCurrentUser, usePermissionCheck } from 'hooks'

import appLogo from 'assets/AcquireLogo.svg'
import okapi from 'assets/Okapi.svg'
// import okapi from 'assets/okapi_holiday.svg'

import LogoutButton from 'components/LogoutButton'

import { client } from 'configureApolloClient'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from 'queries/user'
import { toast } from 'react-toastify'

const Header = ({ new_version_banner_showing }) => {
	const current_user = useCurrentUser()
	const can_change_department = usePermissionCheck(current_user.id, 'change_department', 'User', current_user.id)

	const [updateUser, loading] = useMutation(UPDATE_USER, {
		refetchQueries: ['users'],
		onCompleted: async () => {
			// toast.success('User successfully updated.')
			client.resetStore()
		},
		onError: async (error) => {
			if (error) {
				toast.error(`Error: User was not updated. ${error.message.split(':')[1]}`, {
					autoClose: false,
					hideProgressBar: true,
				})
			}
		},
	})

	const show_logout = current_user.department === 'fmo' || current_user.department === 'client'

	return (
		<Fragment>
			<TransitionGroup
				component="div"
				className="app-header header-shadow"
				style={{ top: new_version_banner_showing ? 35 : 0 }}
				appear
				enter={false}
				exit={false}
			>
				<Transition classNames="HeaderAnimation" timeout={1500}>
					<Fragment>
						<div className="app-header__logo">
							<img
								className="img-fluid"
								style={{ maxHeight: '100%' }}
								src={appLogo}
								alt=""
							/>
						</div>
						<div className="app-header__content">
							<div className="app-header-left"></div>
							<div className="app-header-right">
								{current_user.full_name} - {current_user.email}
								{(can_change_department ||
									current_user.id === 'df6e1353-cec0-44ce-941f-493933c952d5') && (
									<React.Fragment>
										-
										<select
											name="department"
											value={current_user.department}
											onChange={(e) =>
												updateUser({
													variables: {
														input: {
															id: current_user.id,
															department: e.target.value,
														},
													},
												})
											}
											disabled={
												!loading ||
												!can_change_department ||
												current_user.id === 'df6e1353-cec0-44ce-941f-493933c952d5'
											}
										>
											<option value="" disabled>
												Select a department
											</option>
											<option value="client">Client</option>
											<option value="fmo">FMO</option>
											<option value="administration">Administration</option>
											<option value="sales">Sales</option>
											<option value="data">Data</option>
											<option value="art">Art</option>
											<option value="digital">Digital</option>
											<option value="upwork">UpWork</option>
											<option value="production">Production</option>
											<option value="billing">Billing</option>
											<option value="management">God</option>
											<option value="god">God</option>
										</select>
									</React.Fragment>
								)}
								{show_logout && <LogoutButton />}
							</div>
						</div>
					</Fragment>
				</Transition>
			</TransitionGroup>
		</Fragment>
	)
}

export default Header
