import React, { useRef, useEffect, useCallback, useState } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
// import Diff from 'diff'
import { optionsFromStrings, getSelection, getSelectedNodes } from 'utils'

import IO from 'components/Socket/IO'

// import gql from 'graphql-tag'
import useQuery from 'components/UseQuery'
import { useIdentifier, usePrevious, useRunAfterUpdate } from '@joeyparis/hooks'
export * from '@joeyparis/hooks'
export { useCurrentUser } from 'currentUserContext'
export { default as usePermissionCheck } from './usePermissionCheck' // estlint-disable-line no-unresolved
export { default as useClippy } from './useClippy'

export const useQueryWithResultChannels = (query, options = {}) => {
	const [ids, setIds] = useState([])
	const query_result = useQuery(query, { ...options, channels: ids })

	useEffect(() => {
		const objects_with_ids = Object.values(query_result.data)
			.filter((value) => Array.isArray(value) && value[0]?.id)
			.flat()

		setIds(objects_with_ids.map((ob) => ob.id))
	}, [JSON.stringify(query_result.data)])

	return query_result
}

export const useNodeRef = () => {
	const [node, setNode] = useState(null)
	const ref = useCallback((N) => {
		setNode(N)
	})
	return [node, ref]
}

export const useChannels = (callback, channels) => {
	const identifier = useIdentifier()

	const cb = useCallback((message) => {
		callback(message)
	})

	useEffect(() => {
		const io = new IO()
		io.join(channels, identifier)
		channels.forEach((channel) => {
			io.on(channel, (p) => {
				let message
				try {
					message = JSON.parse(p)
				} catch (e) {}
				cb(message)
			})
		})

		return () => {
			io.leave(channels, identifier)
		}
	}, [JSON.stringify(channels)])
}

export const useClickAndDrag = (target) => {
	const [is_dragging, setIsDragging] = useState(false)
	// const [initial_position, setInitialPosition] = useState()
	const [current_position, setCurrentPosition] = useState()
	const previous_position = usePrevious(current_position)

	useEffect(() => {
		const mousedown = (e) => {
			e.preventDefault()
			e.stopPropagation()
			setIsDragging(true)
			// setInitialPosition([e.clientX, e.clientY])
			setCurrentPosition([e.clientX, e.clientY])
		}
		const mousemove = (e) => {
			if (is_dragging) {
				e.preventDefault()
				e.stopPropagation()
				setCurrentPosition([e.clientX, e.clientY])
			}
		}
		const mouseup = (e) => {
			if (is_dragging) {
				e.preventDefault()
				e.stopPropagation()
			}
			setIsDragging(false)
			setCurrentPosition()
		}

		target?.addEventListener('mousedown', mousedown)
		window.addEventListener('mousemove', mousemove)
		window.addEventListener('mouseup', mouseup)

		return () => {
			target?.removeEventListener('mousedown', mousedown)
			window.removeEventListener('mousemove', mousemove)
			window.removeEventListener('mouseup', mouseup)
		}
	}, [target, is_dragging])

	if (!previous_position || !current_position) {
		return { x: 0, y: 0 }
	}

	return {
		x: current_position[0] - previous_position[0],
		y: current_position[1] - previous_position[1],
	}
}

export const useDragAndDrop = (initial_value, target = document.body) => {
	const [is_dragging, setIsDragging] = useState(initial_value)

	useEffect(() => {
		const setDraggingTrue = () => setIsDragging(true)
		const setDraggingFalse = () => setIsDragging(false)

		target.addEventListener('dragover', setDraggingTrue)
		target.addEventListener('drop', setDraggingFalse)
		target.addEventListener('dragleave', setDraggingFalse)

		return () => {
			target.removeEventListener('dragover', setDraggingTrue)
			target.addEventListener('drop', setDraggingFalse)
			target.removeEventListener('dragleave', setDraggingFalse)
		}
	}, [target])

	return is_dragging
}

export const useSelection = (target) => {
	const [selection, setSelection] = useState()

	const setSelected = () => {
		setSelection(getSelection())
		console.info(
			getSelectedNodes().map((n) => n.innerText),
			getSelectedNodes(),
		)
	}

	useEffect(() => {
		if (target) {
			target.addEventListener('mouseup', setSelected)
		}

		return () => {
			if (target) {
				target.removeEventListener('mouseup', setSelected)
			}
		}
	})

	return selection
}

export function useAutocomplete(all_strings) {
	const autocomplete_options = all_strings?.every((s) => s.hasOwnProperty('value') && s.hasOwnProperty('text'))
		? all_strings
		: optionsFromStrings(all_strings)

	function matchAutocomplete(input_val = '') {
		if (input_val.length > 0) {
			const mapped = autocomplete_options.map(({ value, text }) => {
				const whole_match = text.toLowerCase().match(input_val.toLowerCase())
				const letter_match = text.toLowerCase().startsWith(input_val[0].toLowerCase())
				let status = 100
				if (whole_match && letter_match) {
					status = whole_match.index // 0
				} else if (letter_match) {
					status = 1
				} else if (whole_match) {
					status = whole_match.index + 1
				}
				return { text, value, status }
			})
			const sorted = mapped
				.sort((a, b) => a.status - b.status)
				.map((obj) => ({ value: obj.value, text: obj.text }))

			return sorted
		}
		return autocomplete_options
	}

	return [autocomplete_options, matchAutocomplete]
}

export const useKeyboardScroll = ({ initial_options, selected_option, onEnter, required }) => {
	// const selected_option = visible_options && visible_options.find( o => String(o.value) === String(value) )

	const [visible_options, setVisibleOptions] = useState(initial_options || [])
	const [active_index, setActiveIndex] = useState(selected_option ? visible_options.indexOf(selected_option) : 0)

	if (selected_option && visible_options[0] && visible_options[0].text !== '--' && !required) {
		const options_with_empty = [{ text: '--', value: '' }].concat(visible_options)
		setVisibleOptions(options_with_empty)
		setActiveIndex(options_with_empty.indexOf(selected_option))
	}

	function handleKeyboardScroll(e) {
		if (e.key === 'Enter') {
			e.preventDefault()
			onEnter(active_index)
			e.target.blur()
			return false
		}
		if (e.key === 'ArrowUp') {
			if (active_index > 0) {
				setActiveIndex(active_index - 1)
			} else {
				setActiveIndex(visible_options.length - 1)
			}
			return true
		}
		if (e.key === 'ArrowDown') {
			if (active_index < visible_options.length - 1) {
				setActiveIndex(active_index + 1)
			} else {
				setActiveIndex(0)
			}
			return true
		}
		if (e.key === 'Tab') {
			e.target.blur()
			return true
		}
		return true // todo: This might need to be false instead
	}

	function updateOptions(options) {
		setVisibleOptions(options)
		setActiveIndex(selected_option ? visible_options.indexOf(selected_option) : 0)
	}

	return { handleKeyboardScroll, active_index, visible_options, updateOptions, selected_option }
}

export const useCurrencyInput = (setValue) => {
	const input_ref = useRef()
	const previous_value = useRef('0.00')

	const runAfterUpdate = useRunAfterUpdate()

	const handleInputChange = (e) => {
		e.preventDefault()
		if (input_ref.current !== e.target) {
			previous_value.current = e.target.value
		}
		input_ref.current = e.target

		let cursor = e.target.selectionStart

		let new_value = e.target.value

		if (e.target.type === 'search' && new_value === '') {
			setValue(e.target.name, null)
			return false
		}

		if (!new_value || new_value === '0') {
			previous_value.current = '0.00'
			setValue(e.target.name, '0.00')
			return false
		}
		if (new_value.indexOf('.') === -1) {
			new_value = `0.0${new_value}`
			cursor = 4
		}
		if (new_value[new_value.length - 1] === '.') {
			new_value = `${new_value.replace('.', '')}.00`
			cursor = new_value.indexOf('.') + 1
		}

		if (new_value.indexOf('.') !== new_value.lastIndexOf('.')) {
			let counter = 0
			new_value = new_value.replace(/\./g, (_) => (counter++ ? '' : '.'))
		}

		const [new_dollars, new_cents] = new_value.split('.')

		const decimal_position = new_value.indexOf('.')

		//
		if (cursor === decimal_position && new_dollars.substring(0, 1) === '0') {
			cursor -= 1
		}

		let formatted_dollars = new_dollars
		let formatted_cents = new_cents

		// Adjust cents
		if (cursor === decimal_position + 2 && new_value.length >= previous_value.current.length) {
			formatted_cents = `${new_cents[0]}${new_cents[new_cents.length - 1]}`
		}

		// Adding to end of string
		if (cursor === new_value.length && new_cents.length > 2) {
			;[formatted_dollars, formatted_cents] = String(
				(parseInt(new_value.replace(/[^\d-]/g, ''), 10) / 100).toFixed(2),
			).split('.')
		} else {
			formatted_dollars = String(parseInt(new_dollars ? new_dollars.replace(/[^\d-]/g, '') : 0, 10))
			if (Object.is(Math.sign(new_dollars), -0)) formatted_dollars = `-${formatted_dollars}`
			formatted_cents = formatted_cents.substring(0, 2).padEnd(2, '0')
		}

		const formatted_value = `${new Intl.NumberFormat('en').format(formatted_dollars)}.${formatted_cents}`

		setValue(e.target.name, formatted_value)

		const previous_cursor_offset = cursor - (formatted_value.length - previous_value.current.length)

		const previous_commas = previous_value.current.substring(0, previous_cursor_offset).split(',').length - 1
		const new_commas = formatted_value.substring(0, cursor).split(',').length - 1

		// Addjust the cursor offset for the additional commas
		cursor += new_commas - previous_commas

		const moveCursor = () => {
			const elem = input_ref.current
			if (cursor < 0) cursor = 0
			if (elem.createTextRange) {
				const range = elem.createTextRange()
				range.move('character', cursor)
				range.select()
			} else if (elem.selectionStart) {
				elem.focus()
				elem.setSelectionRange(cursor, cursor)
			} else {
				elem.focus()
			}
		}

		if (formatted_value === previous_value.current) {
			setTimeout(moveCursor, 0)
		} else {
			// React won't do another render if the value stays the same, but the browser will still move the cursor
			runAfterUpdate(moveCursor)
		}

		previous_value.current = formatted_value

		return false
	}

	return handleInputChange
}

// export const useCurrencyInput = (setValue) => {
// 	const input_ref = useRef()
// 	const input_name_ref = useRef()

// 	const handleInputChange = (e) => {
// 		e.preventDefault()
// 		input_ref.current = e.target
// 		input_name_ref.current = e.target.name

// 		setValue(input_name_ref.current, e.target.value)
// 	}

// 	const onBlur = () => {
// 		if (input_name_ref.current !== 'quantity') {
// 			setValue(input_name_ref.current, formatCurrency(parseFloat(input_ref.current.value.replace(/[^0-9.-]/g, ''))))
// 		}
// 	}

// 	useEffect(() => {
// 		const input = input_ref.current

// 		if (input) {
// 			input.addEventListener('blur', onBlur)
// 		}

// 		return () => {
// 			if (input) {
// 				input.removeEventListener('blur', onBlur)
// 			}
// 		}
// 	})

// 	return handleInputChange
// }

export const useDifferences = (a, b) => {
	const getDifferences = useCallback(() => {
		const Diff = require('diff')

		const diff = Diff.diffChars(a, b)

		const differences = diff.map((part) => {
			// green for additions, red for deletions
			// grey for common parts
			const color = part.added ? 'green' : part.removed ? 'red' : 'grey'
			// const display = 'initial' || part.added || part.removed ? 'initial' : 'none'

			return <span style={{ color }}>{part.value}</span>
		})

		return differences
	}, [a, b])

	return getDifferences()
}
