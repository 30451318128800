import React, { Component } from 'react'
import Loader from 'react-loaders'

import cx from 'classnames'

export const AppLoader = ({ inline, message }) => (
	<div className={cx('loader-container', { 'loader-container-inline': inline })}>
		<div className="loader-container-inner">
			<div className="text-center">
				<Loader type="ball-scale-ripple-multiple" />
			</div>
			<h6 className="mt-5">Loading...</h6>
			{message && <h6 className="small">{message}</h6>}
		</div>
	</div>
)

export const InlineLoader = ({ message }) => <AppLoader message={message} inline />
