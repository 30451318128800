/**
 *
 * Banners/NewVersion
 *
 */

import React, { memo, useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'

import { useStateAndSessionStorage } from 'persistence-hooks'

import { Row, Col, Button } from 'reactstrap'

// import gql from 'graphql-tag'
// import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isPast, addMinutes, subMinutes } from 'date-fns'

import appLogo from 'assets/LeadJigAcquireLogo.png'
import empty_cache_and_hard_reload from 'assets/video/empty_cache_and_hard_reload.mp4'

const Banner = styled.div`
	position: fixed;
	z-index: 100000; // 1 below BecomeOriginalUserBanner
	top: 0;
	left: 0;
	right: 0;
	// bottom: 0;
	padding: 0.5em;
	background: orange;
	text-align: center;
`

const ModalBanner = styled.div`
	position: fixed;
	z-index: 100000; // 1 below BecomeOriginalUserBanner
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.5em;
	background: #fff;
	text-align: center;
	padding: 10em;
`

function NewVersion({ setNewVersionBannerShowing }) {
	const location = useLocation()
	const [show_new_version_available, setShowNewVersionAvailable] = useState(false)

	const [snooze, setSnooze] = useStateAndSessionStorage(new Date().getTime(), 'new_version_snooze')
	const [snooze_date, setSnoozeDate] = useState(new Date(snooze))

	useEffect(() => {
		if (sessionStorage.getItem('highest_front_end_build_number') > process.env.BUILD_NUMBER) {
			setShowNewVersionAvailable(true)
		}
	}, [location.pathname])

	useEffect(() => {
		if (show_new_version_available) {
			setNewVersionBannerShowing(true)
		} else {
			setNewVersionBannerShowing(false)
		}
	}, [show_new_version_available])

	useEffect(() => {
		setSnoozeDate(new Date(snooze))
	}, [snooze])

	if (!show_new_version_available) {
		return null
	}

	// if (show_new_version_available) {
	// 	window.location = `${process.env.API_URL}/clear-cache`
	// }

	// return null

	if (isPast(snooze_date)) {
		return (
			<ModalBanner style={{ overflow: 'scroll' }}>
				<Row>
					<Col md="6" className="mx-auto">
						<div className="text-center">
							<img className="d-inline img-fluid mb-5" style={{ maxHeight: 100 }} src={appLogo} alt="" />
							<br />
							<p>It looks like you&apos;re on an outdated version of the Acquire App.</p>
							<p>
								You&apos;re on <strong>{process.env.BUILD_NUMBER}</strong> but the latest version is{' '}
								<strong>
									{sessionStorage.getItem('highest_front_end_build_number') ||
										process.env.BUILD_NUMBER}
								</strong>
							</p>
						</div>
						<div className="text-left">
							<p className="mt-5">
								Follow the steps below to clear your cache and start using the latest version right
								away!
							</p>
							<ol style={{ listStyleType: 'decimal', lineHeight: '2em' }}>
								<li>
									<strong>Right-click</strong> anywhere on the current page
								</li>
								<li>
									Click <strong>Inspect</strong> on the context menu
								</li>
								<li>
									<strong>Right-click</strong> the{' '}
									<strong>
										Refresh Icon <FontAwesomeIcon icon="redo-alt" />
									</strong>{' '}
									by the URL bar
								</li>
								<li>
									Click <strong>Empty Cache and Hard Reload</strong>
								</li>
								<li>
									Finally, click{' '}
									<strong>
										<FontAwesomeIcon icon="times" />
									</strong>{' '}
									to close the inspector window
								</li>
							</ol>
						</div>
						<div>
							- or - <br />
							<br />
							<Button
								className="m-1"
								style={{ width: 200 }}
								color="primary"
								onClick={() => setSnooze(addMinutes(new Date(), '5').getTime())}
							>
								Snooze for 5 Minutes
							</Button>
							<br />
							<Button
								className="m-1"
								style={{ width: 200 }}
								color="warning"
								onClick={() => setSnooze(addMinutes(new Date(), '15').getTime())}
							>
								Snooze for 15 Minutes
							</Button>
							<br />
							<Button
								className="m-1"
								style={{ width: 200 }}
								color="danger"
								onClick={() => setSnooze(addMinutes(new Date(), '30').getTime())}
							>
								Snooze for 30 Minutes
							</Button>
						</div>
					</Col>
					<Col md="10" className="mx-auto text-center" style={{ padding: 30 }}>
						<video
							autoPlay
							muted
							loop
							src={empty_cache_and_hard_reload}
							style={{ boxShadow: '5px 5px 15px #000' }}
						/>
					</Col>
				</Row>
			</ModalBanner>
		)
	}

	return (
		<Banner>
			A newer version of Acquire App is available.{' '}
			<button
				type="button"
				className="btn-unstyled text-white"
				onClick={() => setSnooze(subMinutes(new Date(), 1).getTime())}
			>
				<em className="align-middle-center">Clear your cache to update</em>
			</button>
		</Banner>
	)
}

NewVersion.propTypes = {}

export default memo(NewVersion)
