/*
 *
 * LanguageProvider
 *
 * This component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useState, useEffect, createContext, useMemo, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import create from 'zustand'
import { IntlProvider } from 'react-intl'
import { DEFAULT_LOCALE } from 'locales'

import messages from 'translations/translations'

const FORMATS = {
	number: {
		USD: {
			style: 'currency',
			currency: 'USD',
		},
	},
}

export const useLanguageStore = create((set) => ({
	language: '',
	locale: DEFAULT_LOCALE,
	setLanguage: (language) => set({ language }),
	setLocale: (locale) => set({ locale }),
}))

export const LanguageProviderContext = createContext({})

export default function LanguageProvider(props) {
	const locale = useLanguageStore((s) => s.locale)
	const setLocale = useLanguageStore((s) => s.setLocale)

	const language = useLanguageStore((s) => s.language)
	const setLanguage = useLanguageStore((s) => s.setLanguage)
	const [translation, setTranslation] = useState({})

	useEffect(() => {
		if (messages[window.navigator.language]) {
			setLocale(window.navigator.language)
		}
	}, [window.navigator.language])

	useEffect(() => {
		setLanguage(messages[locale])
		setTranslation(messages[locale])
	}, [locale])

	const messages_memo = useMemo(() => ({ ...props.messages[locale], ...language, ...translation }), [
		props.messages,
		locale,
		language,
		translation,
	])

	return (
		<IntlProvider locale={locale} messages={messages_memo} formats={FORMATS} defaultFormats={FORMATS}>
			{React.Children.only(props.children)}
		</IntlProvider>
	)
}

LanguageProvider.propTypes = {
	messages: PropTypes.object,
	children: PropTypes.element.isRequired,
}
