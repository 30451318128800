import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { useCurrentUser, usePermissionCheck, useWindowSize } from 'hooks'

import { TransitionGroup, Transition } from 'react-transition-group'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Hamburger from 'react-hamburgers'

import ChangeLog from 'components/ChangeLog'
import LogoutButton from 'components/LogoutButton'
import Modal from 'components/Modal'

const Sidebar = styled.div`
	width: 44px;
	display: flex;
	z-index: 11;
	overflow: hidden;
	min-width: 225px;
	position: relative;
	flex: 0 0 225px;
	margin-top: -60px;
	padding-top: 60px;
	transition: all 0.2s;
	position: fixed;
	height: 100vh;
	background: #ffffff;
`

const Ul = styled.ul`
	white-space: nowrap;

	a > span {
		transition: opacity 0.2s;
		opacity: ${({ expanded }) => (expanded ? 1 : 0)};
	}
`

const Li = styled.li`
	margin: 0;
	padding: 0;
	position: relative;
	clear: both;

	&.active {
		color: #0780b8;
		background: #e0f3ff;

		> a {
			color: inherit;
			font-weight: bold;
		}

		> ul {
			display: block;

			a {
				background: #fff;
			}
		}
	}

	a {
		transition: transform 0.2s, height 300ms, color 300ms, background-color 300ms;
		display: block;
		align-items: center;
		line-height: 2.5rem;
		height: 50px;
		// padding: 0 1.5rem 0 1rem;
		position: relative;
		border-radius: 0.25rem;
		color: #333333;
		white-space: nowrap;

		span {
			display: inline-block;
			height: 100%;
			line-height: 50px;
		}
	}

	ul {
		display: none;
		background-color: #fff;
		padding-left: 0.75em;
	}

	&.active {
	}
`

const Icon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	float: left;
	width: 64px;
	height: 50px;
	margin-right: -6px;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
	opacity: 0.7;
	line-height: 50px;
	text-align: center;
`

const AppSidebar = ({ expanded, setExpanded }) => {
	const current_user = useCurrentUser()
	const can_view_dashboard = usePermissionCheck(current_user.id, 'view_dashboard', 'User')
	const can_index_vendors = usePermissionCheck(current_user.id, 'index', 'Vendor')
	const can_index_fmos = usePermissionCheck(current_user.id, 'index', 'Fmo')
	const can_index_commissions = usePermissionCheck(current_user.id, 'index', 'CommissionReport')
	const can_view_invoice = usePermissionCheck(current_user.id, 'view', 'Invoice')
	const can_view_transactions = usePermissionCheck(current_user.id, 'view', 'Accounting::Transaction')
	// const can_index_mailer_formats = usePermissionCheck(current_user.id, 'index', 'MailerFormat')
	// const can_index_topics = usePermissionCheck(current_user.id, 'index', 'Topic')
	// const can_index_objectives = usePermissionCheck(current_user.id, 'index', 'Objective')
	// const can_index_digital_templates = usePermissionCheck(current_user.id, 'index', 'DigitalTemplate')
	const [show_change_log, setShowChangeLog] = useState(false)

	const can_index_mailer_formats = usePermissionCheck(current_user.id, 'index', 'MailerFormat')
	const can_index_post_offices = usePermissionCheck(current_user.id, 'index', 'PostOffice')
	const can_index_blackout_dates = usePermissionCheck(current_user.id, 'index', 'BlackoutDate')
	const can_index_topics = usePermissionCheck(current_user.id, 'index', 'Topic')
	const can_index_objectives = usePermissionCheck(current_user.id, 'index', 'Objective')
	const can_index_venue_types = usePermissionCheck(current_user.id, 'index', 'VenueType')
	const can_index_venue_brands = usePermissionCheck(current_user.id, 'index', 'VenueBrand')
	const can_index_subscription_services = usePermissionCheck(current_user.id, 'index', 'SubscriptionService')
	const can_index_digital_templates = usePermissionCheck(current_user.id, 'index', 'DigitalTemplate')
	const can_index_digital_template_images = usePermissionCheck(current_user.id, 'index', 'DigitalTemplateImage')
	const can_index_mailer_templates = usePermissionCheck(current_user.id, 'index', 'MailerTemplate')

	const can_view_config =
		can_view_invoice ||
		can_index_mailer_formats ||
		can_index_post_offices ||
		can_index_topics ||
		can_index_objectives ||
		can_index_venue_types ||
		can_index_venue_brands ||
		can_index_subscription_services ||
		can_index_digital_templates ||
		can_index_digital_template_images ||
		can_index_mailer_templates

	const { width } = useWindowSize()
	const is_mobile = width < 665

	const [minWidth, setMinWidth] = useState(225)

	useEffect(() => {
		if (expanded && is_mobile) {
			setMinWidth('100%')
		} else if (expanded) {
			setMinWidth(225)
		} else {
			setMinWidth(64)
		}
	}, [is_mobile, expanded])

	const NavItem = ({ path, icon, label, hidden, children }) => {
		const location = useLocation()

		if (hidden) {
			return null
		}

		return (
			<Li className={location.pathname.startsWith(path) ? 'active' : ''}>
				<Link to={path} onClick={() => {if(is_mobile) setExpanded(false)}}>
					<Icon>
						<FontAwesomeIcon fixedWidth icon={['fal', icon]} style={{ fontSize: '1.75em' }} />
					</Icon>
					<span>{label}</span>
				</Link>
				{children}
			</Li>
		)
	}

	return (
		<React.Fragment>
			{is_mobile && !expanded && (
				<div style={{ position: 'absolute', zIndex: 1001, display: 'inline-block', transform: 'scale(0.5)' }}>
					<Hamburger active={expanded} type="slider" onClick={() => setExpanded(!expanded)} />
				</div>
			)}
			<TransitionGroup
				component={Sidebar}
				className="app-sidebar"
				style={{
					minWidth,
					left: is_mobile && !expanded ? -64 : 0,
					zIndex: is_mobile && expanded ? 1001 : 11,
				}}
				appear
				enter={false}
				exit={false}
			>
				<Transition timeout={1500}>
					<PerfectScrollbar
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
						options={{
							suppressScrollX: true,
						}}
					>
						<div>
							<Ul expanded={expanded}>
								<Li>
									<div style={{ display: 'inline-block', transform: 'scale(0.5)' }}>
										<Hamburger
											active={expanded}
											type="slider"
											onClick={() => setExpanded(!expanded)}
										/>
									</div>
								</Li>
								{can_view_dashboard && (
									<NavItem path="/dashboard" icon="dharmachakra" label="Dashboard" />
								)}
								<NavItem path="/tasks" icon="tasks" label="Task List" />
								<NavItem path="/calendar" icon="calendar-alt" label="Calendar" />
								<NavItem path="/orders" icon="inbox" label="Orders" />
								<NavItem path="/venues" icon="utensils" label="Venues" />
								<NavItem path="/subscriptions" icon="repeat" label="Subscriptions" />
								<NavItem path="/users" icon="users" label="Users" />
								<NavItem path="/clients" icon="gem" label="Clients" />
								{/* <NavItem path="/pto" icon="island-tropical" label="PTO" /> */}
								{can_index_fmos && <NavItem path="/fmos" icon="building" label="FMOs" />}
								{can_index_vendors && <NavItem path="/vendors" icon="anchor" label="Vendors" />}
								{/* <NavItem path="/sales_summary" icon="file-chart-pie" label="Sales Summary" /> */}
								{can_index_commissions && (
									<NavItem path="/commission" icon="money-check-alt" label="Commission">
										<ul>
											<NavItem
												path="/commission/unpaid"
												icon="search-dollar"
												label="Unpaid Invoices"
											/>
											<NavItem
												path="/commission/outstanding"
												icon="file-invoice"
												label="Outstanding"
											/>
											<NavItem
												path="/commission/reports"
												icon="file-invoice-dollar"
												label="Reports"
											/>
										</ul>
									</NavItem>
								)}
								{can_view_invoice && (
									<NavItem path="/accounting" icon="piggy-bank" label="Accounting">
										<ul>
											<NavItem path="/accounting/products" icon="briefcase" label="Products" />
											<NavItem path="/accounting/invoices" icon="file-invoice" label="Invoices" />
											{can_view_transactions && (
												<NavItem
													path="/accounting/transactions"
													icon="funnel-dollar"
													label="Transactions"
												/>
											)}
											<NavItem
												path="/accounting/digital_only"
												icon="icons"
												label="Digital Only"
											/>
										</ul>
									</NavItem>
								)}
								{can_view_config && (
									<NavItem path="/config" icon="cogs" label="Config">
										<ul>
											<NavItem
												path="/config/mailer_formats"
												icon="envelope"
												label="Mailer Formats"
												hidden={!can_index_mailer_formats}
											/>
											{/*<NavItem
												path="/config/mailer_templates"
												icon="mail-bulk"
												label="Mailer Templates"
												hidden={!can_index_mailer_templates}
											/>*/}
											<NavItem
												path="/config/post_offices"
												icon="mailbox"
												label="Post Offices"
												hidden={!can_index_post_offices}
											/>
											<NavItem
												path="/config/blackout_dates"
												icon="calendar-alt"
												label="Blackout Dates"
												hidden={!can_index_blackout_dates}
											/>
											<NavItem
												path="/config/topics"
												icon="newspaper"
												label="Topics"
												hidden={!can_index_topics}
											/>
											<NavItem
												path="/config/objectives"
												icon="bullseye"
												label="Objectives"
												hidden={!can_index_objectives}
											/>
											<NavItem
												path="/config/venue_types"
												icon="store"
												label="Venue Types"
												hidden={!can_index_venue_types}
											/>
											<NavItem
												path="/config/venue_brands"
												icon="utensils"
												label="Venue Brands"
												hidden={!can_index_venue_brands}
											/>
											<NavItem
												path="/config/subscription_services"
												icon="repeat"
												label="Subscription Services"
												hidden={!can_index_subscription_services}
											/>
											<NavItem
												path="/config/digital_templates"
												icon="icons"
												label="Digital Templates"
												hidden={!can_index_digital_templates}
											/>
											<NavItem
												path="/config/digital_template_images"
												icon="image-polaroid"
												label="Digital Images"
												hidden={!can_index_digital_template_images}
											/>
										</ul>
									</NavItem>
								)}
							</Ul>
						</div>
						<div className="text-center pb-2">
							<LogoutButton />
							<small style={{ fontSize: expanded ? '80%' : '70%' }}>
								<button className="btn-unstyled" type="button" onClick={() => setShowChangeLog(true)}>
									Build: {process.env.ENV[0].toUpperCase()}.{process.env.BUILD_NUMBER}
								</button>
								<br />
								<a href={`${process.env.API_URL}/clear-cache`}>Clear Cache</a>
							</small>
						</div>
					</PerfectScrollbar>
				</Transition>
			</TransitionGroup>
			<Modal show={show_change_log} modalDidClose={() => setShowChangeLog(false)} style={{ width: 900 }}>
				<ChangeLog />
			</Modal>
		</React.Fragment>
	)
}

export default AppSidebar
