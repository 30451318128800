import axios from 'axios'
import moment from 'moment'
import { escapeRegExp, titleize } from '@joeyparis/utils'
export * from '@joeyparis/utils'

export const uploadFiles = (options) =>
	new Promise((resolve, reject) => {
		let files = options.files || []
		if (FileList.name === files.constructor.name) files = [...files]
		if (options.file && File.name === options.file.constructor.name) files.push(options.file)

		if (!Array.isArray(files)) {
			reject(new Error('`files` attribute must be an array type or a FileList object.'))
			return
		}

		if (!options.url || !options.attribute || files.length < 1) {
			reject(new Error('URL, attribute, and file(s) are all required options.'))
			return
		}

		const form = new FormData()
		files.forEach((file) =>
			form.append(
				`${options.attribute}[]`,
				new Blob([file], {
					encoding: 'UTF-8',
					type: 'text/plain;charset=UTF-8',
				}),
			),
		)

		axios({
			method: 'POST',
			url: options.url,
			data: form,
		})
			.then((response) => {
				resolve(response)
			})
			.catch((err) => {
				reject(err)
			})

		// const request = new XMLHttpRequest()
		// request.open('POST', options.url)
		// request.send(form)
		// request.onload = () => {
		// 	if (request.status >= 200 && request.status < 300) {
		// 		resolve(request)
		// 		return
		// 	}
		// 	reject(request)
		// }
	})

export const filePathsToTree = (paths = []) => {
	if (paths.length === 0) return {}

	paths = paths.map((p) => p.replace(/^\//, ''))

	const folder_paths = Array.from(
		new Set(
			paths.flatMap((p) => {
				const folders = p.split('/')
				if (p.charAt(p.length - 1) !== '/') {
					folders.pop()
				}

				const folders_to_add = []
				while (folders.length > 0) {
					folders_to_add.push(`${folders.join('/')}/`)
					folders.pop()
				}
				return folders_to_add
			}),
		),
	).sort()

	paths = [...folder_paths, ...paths]

	// Extract a filename from a path
	const getFilename = (path) =>
		path
			.split('/')
			.filter((value) => value && value.length)
			.reverse()[0]

	// Find sub paths
	const findSubPaths = (path) => {
		// slashes need to be escaped when part of a regexp
		const rePath = escapeRegExp(path)
		const re = new RegExp(`^${rePath}[^\\/]*\\/?$`)
		return paths.filter((i) => i !== path && re.test(i))
	}

	// Build tree recursively
	const buildTree = (path = '') => {
		const nodeList = []
		findSubPaths(path).forEach((subPath) => {
			const nodeName = getFilename(subPath)
			if (/\/$/.test(subPath)) {
				const node = {}
				node[nodeName] = buildTree(subPath)
				nodeList.push(node)
			} else {
				nodeList.push(nodeName)
			}
		})
		return nodeList
	}

	// Build tree from root
	let tree = buildTree()

	// By default, tree is an array
	// If it contains only one element which is an object,
	// return this object instead to match OP request
	if (tree.length === 1 && typeof tree[0] === 'object') {
		tree = tree[0]
	}

	return tree
}

export const s3FilesToTree = (original_files = []) => {
	const standardize_file_name = (file) => {
		let { name } = file
		if (name[0] !== '/') {
			name = `/${name}`
		}

		name = `/${file.category ? titleize(file.category) : 'Uncategorized'}${name}`

		return name
	}

	const files = original_files.map((f) => ({
		...f,
		standardized_name: standardize_file_name(f),
	}))

	const folder_paths = Array.from(
		new Set(
			files.flatMap((f) => {
				const folders = f.standardized_name.split('/')
				folders.pop() // Last file is always the file standardized_name

				const folders_to_add = []
				while (folders.length > 0) {
					folders_to_add.push(`${folders.join('/')}/`)
					folders.pop()
				}
				return folders_to_add
			}),
		),
	)
		.sort()
		.map((standardized_name) => ({ standardized_name }))

	// Extract a filename from a path
	const getFilename = (file) =>
		file.standardized_name
			.split('/')
			.filter((value) => value && value.length)
			.reverse()[0]

	// Find sub paths
	const findSubPaths = (file) => {
		// slashes need to be escaped when part of a regexp
		const rePath = file.standardized_name ? escapeRegExp(file.standardized_name) : ''
		const re = new RegExp(`^${rePath}[^\\/]*\\/?$`)
		return [
			...files.filter((i) => i.standardized_name !== file.standardized_name && re.test(i.standardized_name)),
			...folder_paths.filter(
				(i) => i.standardized_name !== file.standardized_name && re.test(i.standardized_name),
			),
		]
	}

	// Build tree recursively
	const buildTree = (file = { standardized_name: '/' }) => {
		const nodeList = []
		findSubPaths(file).forEach((subFile) => {
			const nodeName = getFilename(subFile)
			if (/\/$/.test(subFile.standardized_name)) {
				const node = {}
				node[nodeName] = buildTree(subFile)
				nodeList.push(node)
			} else {
				nodeList.push(subFile)
			}
		})
		return nodeList
	}

	// Build tree from root
	const tree = buildTree()

	// By default, tree is an array
	// If it contains only one element which is an object,
	// return this object instead to match OP request
	// if (tree.length === 1 && typeof tree[0] === 'object') {
	// 	tree = tree[0]
	// }

	return tree
}

/* eslint-disable prettier/prettier */
export const sanitizeDraftJsMap = (raw_content) => ({
	blocks: raw_content.blocks ? raw_content.blocks.map((block) => (block.text ? block : { ...block, text: '' })) : [],
	entityMap:
		raw_content.entityMap && raw_content.entityMap[0]
			? raw_content.entityMap
			: {
				data: raw_content.entityMap.data ? raw_content.entityMap.data : '',
				type: raw_content.entityMap.type ? raw_content.entityMap.type : '',
				mutability: raw_content.entityMap.mutability ? raw_content.entityMap.mutability : '',
			  },
})
/* eslint-enable prettier/prettier */

export const formatCurrencyLegacy = (number_or_string) => {
	if (!number_or_string || String(number_or_string) === '0') return ''

	let number_string = String(number_or_string).replace(/[^0-9,.]/g, '')
	if (number_string.includes('.')) {
		// let [dollars, cents] = number_string.split(".")
		// [dollars, cents && cents.slice(0,2)].join(".")
		number_string = number_string.substr(0, number_string.indexOf('.') + 3)
	}
	return number_string
}

export const parseCurrencyFloat = (string = '') => parseFloat(String(string).replace(/[^\d.\-e]/g, ''))

export const sortKeysByDayOfWeek = (obj) => {
	const sorted = {}
	Object.keys(obj)
		.sort((a, b) => moment(a, 'ddd dddd').weekday() > moment(b, 'ddd dddd').weekday())
		.forEach((key) => {
			sorted[key] = obj[key]
		})
	return sorted
}

export const getSelection = () => {
	if (window.getSelection) {
		return window.getSelection()
	}
	if (document.getSelection) {
		return document.getSelection()
	}
	if (document.selection) {
		return document.selection.createRange().text
	}
}

export const backParam = (text) => {
	let query_string = `back=${encodeURIComponent(window.location.pathname + window.location.hash)}`
	if (text) {
		query_string += `&back_text=${encodeURIComponent(text)}`
	}
	return query_string
}

export const calculateChartHeight = (data_length) => {
	let height = data_length * 20 + 200
	if (height < 420) {
		height = 420
	}
	return height
}

export const assumeBoundingBox = ({ width, height, left, top, right, bottom }) => {
	// Parse float will return NaN if null, empty string, etc. which can be used for the proceding boolean tests
	const has = (v) => !Number.isNaN(parseFloat(v))
	// Left, Right
	// Left, Width
	// Right, Width

	if (!has(width) && has(left) && has(right)) {
		// Good
		width = right - left
	}
	if (has(width) && !has(left) && has(right)) {
		// Good
		left = right - width
	}
	if (has(width) && has(left) && !has(right)) {
		// Good
		right = left + width
	}
	if (has(width) && has(left) && has(right)) {
		// Okay, width ignored (maybe right ignored)
		if (width !== right - left) {
			width = right - left
			// Or, right = left + width
			console.warn(
				'Width, left, and right all provided; overriding width with difference between left and right.',
			)
		}
	}
	if (has(width) && !has(left) && !has(right)) {
		// Bad (unless we assume left is 0)
		left = 0
		right = left + width
		console.warn('No left or right provided, assuming left as 0')
	}
	if (
		(!has(width) && has(left) && !has(right)) ||
		(!has(width) && !has(left) && has(right)) ||
		(!has(width) && !has(left) && !has(right))
	) {
		console.error('Width or (left and right) required.')
	}

	// Top, Height
	// Bottom, Height
	// Top, Bottom
	if (!has(height) && has(top) && has(bottom)) {
		// Good
		height = bottom - top
	}
	if (has(height) && !has(top) && has(bottom)) {
		// Good
		top = bottom - height
	}
	if (has(height) && has(top) && !has(bottom)) {
		// Good
		bottom = top + height
	}
	if (has(height) && has(top) && has(bottom)) {
		// Okay, height ignored (maybe bottom ignored)
		if (height !== bottom - top) {
			height = bottom - top
			// Or, bottom = top + height
			console.warn(
				'Height, top, and bottom all provided; overriding height with difference between top and bottom.',
			)
		}
	}
	if (has(height) && !has(top) && !has(bottom)) {
		// Bad (unless we assume top is 0)
		top = 0
		bottom = top + height
		console.warn('No top or bottom provided, assuming top as 0')
	}
	if (
		(!has(height) && has(top) && !has(bottom)) ||
		(!has(height) && !has(top) && has(bottom)) ||
		(!has(height) && !has(top) && !has(bottom))
	) {
		console.error('Height or (top and bottom) required.')
	}

	const assumed_bounding_box = { width, height, left, top, right, bottom }

	// const too_far_right = assumed_bounding_box.right > window.pageXOffset + window.innerWidth
	// const too_far_left = assumed_bounding_box.left < window.pageXOffset
	// const too_far_down = assumed_bounding_box.bottom > window.pageYOffset + window.innerHeight
	// const too_far_up = assumed_bounding_box.top < window.pageYOffset

	const left_edge = 0 + window.pageXOffset
	const right_edge = window.pageXOffset + window.innerWidth
	const top_edge = 0 + window.pageYOffset
	const bottom_edge = window.pageYOffset + window.innerHeight

	const needs_to_move_left = Math.max(0, assumed_bounding_box.right - right_edge)
	const needs_to_move_up = Math.max(0, assumed_bounding_box.bottom - bottom_edge)
	const needs_to_move_right = Math.min(0, assumed_bounding_box.left - left_edge)
	const needs_to_move_down = Math.min(0, assumed_bounding_box.top - top_edge)

	const can_move_left = assumed_bounding_box.left - left_edge
	const can_move_up = assumed_bounding_box.top - top_edge
	const can_move_right = right_edge - assumed_bounding_box.right
	const can_move_down = bottom_edge - assumed_bounding_box.bottom

	const move_left = can_move_left < needs_to_move_left ? can_move_left : needs_to_move_left
	const move_right = can_move_right < needs_to_move_right ? can_move_right : needs_to_move_right
	const move_up = can_move_up < needs_to_move_up ? can_move_up : needs_to_move_up
	const move_down = can_move_down < needs_to_move_down ? can_move_down : needs_to_move_down

	return [
		assumed_bounding_box,
		{
			needs_to_move_down,
			needs_to_move_left,
			needs_to_move_right,
			needs_to_move_up,
		},
		{
			can_move_down,
			can_move_left,
			can_move_right,
			can_move_up,
		},
		{
			move_down,
			move_left,
			move_right,
			move_up,
		},
	]
}

const breakpoint_values = { xs: 0, sm: 665, md: 1080, lg: 1489, xl: 2000 }
const mq = {}
Object.keys(breakpoint_values).forEach((key) => {
	mq[key] = `@media (min-width: ${breakpoint_values[key]}px)`
})
export const breakpoints = mq
