
      import API from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../.yarn/__virtual__/style-loader-virtual-076ebb3040/0/cache/style-loader-npm-3.1.0-7a89e7a683-bf7e8d2cc9.zip/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../.yarn/__virtual__/css-loader-virtual-481dfa1fdd/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/cjs.js!./hamburgers.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../.yarn/__virtual__/css-loader-virtual-481dfa1fdd/0/cache/css-loader-npm-5.2.7-e1e8b8d16f-fb0742b30a.zip/node_modules/css-loader/dist/cjs.js!./hamburgers.css";
       export default content && content.locals ? content.locals : undefined;
