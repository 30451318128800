import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import useQuery from 'components/UseQuery'
import { gql } from '@apollo/client'
import { GET_CURRENT_USER } from 'queries/user'

import Sentry from 'configureSentry'

const CurrentUserContext = React.createContext({})

const withCurrentUser = (Component) => (props) => (
	<CurrentUserContext.Consumer>
		{({ current_user }) => <Component {...props} current_user={current_user} />}
	</CurrentUserContext.Consumer>
)

const useCurrentUser = () => useContext(CurrentUserContext).current_user

function CurrentUserProvider({ children }) {
	// const navigate = useNavigate()
	const [channels, setChannels] = useState([])

	const {
		data: { current_user },
		loading,
		refetch,
		error,
	} = useQuery(GET_CURRENT_USER, {
		default_data: { current_user: {} },
		channels,
	})

	useEffect(() => {}, [loading, current_user])

	useEffect(() => {
		setChannels([current_user.id, current_user.lead_jig_user_id])

		Sentry.setUser({
			id: current_user.id,
			email: current_user.email,
			username: current_user.full_name,
		})
	}, [current_user.id, current_user.email, current_user.lead_jig_user_id])

	useEffect(() => {
		if (error && !current_user.id) {
			if (
				window.location.pathname !== '/login' &&
				window.location.pathname !== '/users/password/edit' &&
				window.location.pathname !== '/ftp_fc' &&
				!window.location.pathname.startsWith('/rsvp/')
			) {
				window.location = `${process.env.API_URL}`
			}
		}
	}, [current_user.id, error])

	const value = useMemo(
		() => ({
			current_user,
			refetch,
			loading,
		}),
		[current_user, refetch, loading],
	)

	return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export default CurrentUserProvider
export { CurrentUserContext, withCurrentUser, useCurrentUser }
