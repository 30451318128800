/**
 *
 * /ChangeLog
 *
 */

import React, { memo } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'

import gql from 'graphql-tag'
import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/react-hooks'

import ReactMarkdown from 'react-markdown'

const GET_CHANGE_LOG = gql`
	query change_log {
		change_log
	}
`

function ChangeLog() {
	const {
		data: { change_log },
	} = useQuery(GET_CHANGE_LOG, { default_data: { change_log: '' } })

	return (
		<div>
			<ReactMarkdown>{change_log}</ReactMarkdown>
		</div>
	)
}

ChangeLog.propTypes = {}

export default memo(ChangeLog)
