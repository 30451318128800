/**
 *
 * /Modal
 *
 */

import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
// import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import classNames from 'classnames'

// import gql from 'graphql-tag'
// import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/react-hooks'

import ErrorBoundary from 'components/ErrorBoundary'

// import { FormattedMessage } from 'react-intl'
// import messages from './messages'

const styles = css`
	.modalContainer {
		display: none;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 1rem;

		z-index: 1050;
	}

	.modalContainer.modalContainer_open {
		display: flex;

		background-color: rgba(0, 0, 0, 0.4);
	}

	.modalWrapper {
		background-color: #fff;
		// border-radius: 5px;
		margin: auto;
		overflow-x: hidden;
		overflow-y: auto;
		display: none;
		position: absolute;
		max-width: 100%;
		max-height: 100%;
	}

	.modalContainer.modalContainer_show {
		background-color: rgba(0, 0, 0, 0.4);

		transition: background-color 100ms;
	}

	.modalContainer.modalContainer_hide {
		background-color: rgba(0, 0, 0, 0);

		transition: background-color 100ms 200ms;
	}

	.modalWrapper.modalWrapper_show {
		animation: showModal 300ms;
	}

	.modalWrapper.modalWrapper_hide {
		animation: hideModal 300ms;
	}

	@keyframes showModal {
		0% {
			transform: scale(0.7);
		}

		45% {
			transform: scale(1.05);
		}

		80% {
			transform: scale(0.95);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes hideModal {
		0% {
			transform: scale(1);
			opacity: 1;
		}

		100% {
			transform: scale(0.5);
			opacity: 0;
		}
	}
`

function Modal(props) {
	const defaultWrapperStyle = {
		width: '600px',
		padding: '2rem',
		background: 'rgb(255, 255, 255)',
		display: 'block',
		minHeight: '148px',
	}

	const wrapperStyle = { ...defaultWrapperStyle, ...props.style }

	const modalContainer = useRef()
	const modalWrapper = useRef()

	const [isShowing, setIsShowing] = useState(false)
	const [isHiding, setIsHiding] = useState(false)
	const [open, setIsOpen] = useState(props.show)

	useEffect(() => {
		setIsOpen(props.show)
	}, [props.show])

	const modalWillShow = () => {
		if (isShowing || open) return false

		if (props.debug) console.info('Modal will show')

		if (props.modalWillShow) {
			if (props.modalWillShow() === false) return false
		}

		return true
	}

	const modalDidShow = () => {
		if (props.debug) console.info('Modal did show')

		if (props.modalDidShow) props.modalDidShow()
	}

	const modalWillClose = () => {
		if (isHiding || !open) return false

		if (props.debug) console.info('Modal will close')

		if (props.modalWillClose) if (props.modalWillClose() === false) return false

		return true
	}

	const modalDidClose = () => {
		if (props.debug) console.info('Modal did close')

		if (props.modalDidClose) props.modalDidClose()
	}

	const showModal = () => {
		if (modalWillShow()) {
			setIsShowing(true)
			setIsOpen(true)

			setTimeout(() => {
				setIsShowing(false)
				modalDidShow()
			}, 280)
		}
	}

	const closeModal = () => {
		if (modalWillClose()) {
			setIsHiding(true)

			setTimeout(() => {
				setIsHiding(false)
				setIsOpen(false)
				modalDidClose()
			}, 280)
		}
	}

	const dismissModal = (e) => {
		if (modalContainer.current === e.target) closeModal()
	}

	useEffect(() => {
		if (props.show) showModal()
		else closeModal()
	}, [props.show])

	const modalContainerClasses = classNames(`modalContainer ${props.containerClassName}`, {
		modalContainer_show: isShowing,
		modalContainer_hide: isHiding,
		modalContainer_open: open,
	})

	const modalWrapperClasses = classNames('modalWrapper', {
		modalWrapper_show: isShowing,
		modalWrapper_hide: isHiding,
	})

	return ReactDOM.createPortal(
		<React.Fragment>
			<Global styles={styles} />
			<div
				role="button"
				tabIndex={-1}
				ref={modalContainer}
				className={modalContainerClasses}
				onMouseDown={dismissModal}
				style={props.containerStyle}
			>
				<div ref={modalWrapper} className={modalWrapperClasses} style={wrapperStyle}>
					{open && <ErrorBoundary message="Oops! Something went wrong.">{props.children}</ErrorBoundary>}
				</div>
			</div>
		</React.Fragment>,
		document.body,
	)
}

Modal.propTypes = {}

Modal.defaultProps = {}

export default Modal
