/**
 *
 * /LogoutButton
 *
 */

import React, { memo, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { useCurrentUser } from 'hooks'

// import gql from 'graphql-tag'
// import useQuery from 'components/UseQuery'
// import { useMutation } from '@apollo/react-hooks'

import axios from 'axios'
import client from 'configureApolloClient'
// import { CurrentUserContext } from 'currentUserContext'

import DeleteButton from 'components/DeleteButton'

import { isPast, addMinutes } from 'date-fns'

function LogoutButton() {
	// const { refetch } = useContext(CurrentUserContext)
	const current_user = useCurrentUser()
	const location = useLocation()

	const [snooze, setSnooze] = useState(new Date())

	const logout = (redirect_url = `${process.env.API_URL}/unauthorized`) => {
		axios({
			method: 'DELETE',
			url: `${process.env.API_URL}/sign_out`,
			maxRedirects: 0,
		})
			.then(() => {})
			.catch((err) => {
				if (err.response.status === 404) {
					client.resetStore()
					window.location = redirect_url
				}
			})
	}

	useEffect(() => {
		if (current_user.force_logout && isPast(snooze)) {
			if (
				confirm(
					'This update requires you to logout. To prevent you from losing any data you may dismiss this message for 1 minute by clicking "Cancel". You should be automatically signed back in after pressing "OK".',
				)
			) {
				logout(window.location)
			} else {
				setSnooze(addMinutes(new Date(), 1))
			}
		}
	}, [location.pathname])

	return (
		<React.Fragment>
			<DeleteButton type="button" className="btn-unstyled" onDelete={logout} message="Really Logout?">
				Logout
			</DeleteButton>
			<br />
		</React.Fragment>
	)
}

LogoutButton.propTypes = {}

export default memo(LogoutButton)
