import { css } from '@emotion/react'
import 'assets/base.scss'
import 'assets/react_select_search.css'
import 'assets/hamburgers.css'

export const COLORS = {
	primary: '#0780b8',
	info: 'lightblue',
	success: '#3ac47d',
	warning: '#f68b1f',
	// danger: '#d92550',
	danger: '#ff2400',
	grey: 'grey',
	white: '#fff',
}

const GlobalStyle = css`
	html,
	body {
		height: 100%;
		width: 100%;
	}

	body {
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	}

	#app {
		min-height: 100%;
		min-width: 100%;
	}

	input,
	select {
		font-family: inherit;
		font-size: inherit;
	}

	.clickable {
		cursor: pointer;
	}

	.text-strike {
		text-decoration: line-through;
	}

	input[readonly] {
		outline: none;
	}

	.react-datepicker-popper {
		z-index: 1001;
	}
`

export default GlobalStyle
