import React, { useState, useRef, useEffect, useContext } from 'react'
import { Global, css } from '@emotion/react'
// import clippyjs from 'clippyjs'

window.CLIPPY_CDN = 'https://cdn.jsdelivr.net/gh/pi0/clippyjs/assets/agents/'

const clippy_styles = css`
	.clippy,
	.clippy-balloon {
		position: fixed;
		z-index: 100000000000;
		cursor: pointer;
	}

	.clippy-balloon {
		background: #ffc;
		color: black;
		padding: 8px;
		border: 1px solid black;
		border-radius: 5px;
	}

	.clippy-content {
		max-width: 200px;
		min-width: 120px;
		font-family: 'Microsoft Sans', sans-serif;
		font-size: 10pt;
	}

	.clippy-tip {
		width: 10px;
		height: 16px;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAMAAAAlvKiEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAlQTFRF///MAAAA////52QwgAAAAAN0Uk5T//8A18oNQQAAAGxJREFUeNqs0kEOwCAIRFHn3//QTUU6xMyyxii+jQosrTPkyPEM6IN3FtzIRk1U4dFeKWQiH6pRRowMVKEmvronEynkwj0uZJgR22+YLopPSo9P34wJSamLSU7lSIWLJU7NkNomNlhqxUeAAQC+TQLZyEuJBwAAAABJRU5ErkJggg==)
			no-repeat;
		position: absolute;
	}

	.clippy-top-left .clippy-tip {
		top: 100%;
		margin-top: 0px;
		left: 100%;
		margin-left: -50px;
	}

	.clippy-top-right .clippy-tip {
		top: 100%;
		margin-top: 0px;
		left: 0;
		margin-left: 50px;
		background-position: -10px 0;
	}

	.clippy-bottom-right .clippy-tip {
		top: 0;
		margin-top: -16px;
		left: 0;
		margin-left: 50px;
		background-position: -10px -16px;
	}

	.clippy-bottom-left .clippy-tip {
		top: 0;
		margin-top: -16px;
		left: 100%;
		margin-left: -50px;
		background-position: 0 -16px;
	}
`

const ClippyContext = React.createContext({})

const useClippy = () => useContext(ClippyContext)

const ClippyProvider = ({ children }) => {
	const [clippy, setClippy] = useState()

	useEffect(() => {
		;(async() => {
			const { default: clippyjs } = await import('clippyjs')

			clippyjs.load('Clippy', (agent) => {
				setClippy(agent)
				window.clippy = agent
			})
		})()

		return () => {
			if (clippy) {
				clippy.hide()
			}
		}
	}, [])

	return (
		<ClippyContext.Provider value={clippy}>
			{children}
			<Global styles={clippy_styles} />
		</ClippyContext.Provider>
	)
}

export { ClippyProvider, useClippy }

export default useClippy
