import { gql } from '@apollo/client'
import useQuery from 'components/UseQuery'

const PERMISSION_CHECK = gql`
	query permission_check($user_id: ID!, $action: String!, $object_class: String!, $object_id: ID) {
		permission_check(user_id: $user_id, action: $action, object_class: $object_class, object_id: $object_id)
	}
`

const usePermissionCheck = (user_id, action, object_class, object_id) => {
	const { data } = useQuery(PERMISSION_CHECK, {
		variables: {
			user_id,
			action,
			object_class,
			object_id,
		},
		channels: [user_id],
		skip: !user_id,
	})

	return data && data.permission_check
}

export default usePermissionCheck
