/**
 *
 * /DeleteButton
 *
 */

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import { useOnClickOutside } from 'hooks'

// import gql from 'graphql-tag'
// import Query from 'components/Query'
// import { Mutation } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { FormattedMessage } from 'react-intl'
// import messages from './messages'

function DeleteButton({ className, style, onDelete, children, message, deleting, loading_message, hidden }) {
	const button_ref = useRef()

	const [clicked_at, setClickedAt] = useState(null)
	const [cancel_timer, setCancelTimer] = useState(null)
	const deleting_message = loading_message || '- Deleting'

	useOnClickOutside(button_ref, () => clicked_at && setClickedAt(null))

	return (
		<button
			ref={button_ref}
			type="button"
			className={className ? className : 'btn-unstyled text-left text-danger d-inline-block'}
			onClick={(e) => {
				e.stopPropagation()
				if (clicked_at) {
					if (cancel_timer) {
						clearTimeout(cancel_timer)
					}
					if (clicked_at && new Date() - clicked_at > 200) {
						// enforces brief wait between clicks
						onDelete()
					}
				} else {
					setClickedAt(new Date())
				}
			}}
			onMouseLeave={() => {
				if (cancel_timer) {
					clearTimeout(cancel_timer)
				}
				setCancelTimer(
					setTimeout(() => {
						setClickedAt(null)
					}, 2000),
				)
			}}
			style={style ? style : { opacity: hidden ? 0 : 1, outline: 'none', padding: '0.75rem 1.25rem' }}
		>
			{children || <FontAwesomeIcon icon="trash" />}
			<span
				style={{
					maxWidth: clicked_at ? '400px' : 0,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					display: 'inline-block',
					transition: 'max-width 0.3s ease-in-out',
					verticalAlign: 'bottom',
					marginLeft: clicked_at ? '.5rem' : 0,
				}}
			>
				{deleting ? deleting_message : message || ' Really Delete?'}
			</span>
		</button>
	)
}

DeleteButton.propTypes = {
	onDelete: PropTypes.func.isRequired,
	message: PropTypes.string,
	hidden: PropTypes.bool,
}

export default DeleteButton
