/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// import './wdyr'
window.Buffer = require('buffer/').Buffer

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'
import 'sanitize.css/sanitize.css'
import 'assets/tailwind.css'

import 'configureAxios'
import 'configureSentry'
import * as Sentry from '@sentry/react'

// Import root app
import App from 'components/App'

import history from 'utils/history'

// Import Language Provider
import LanguageProvider from 'providers/LanguageProvider'

// Import Apollo provider and client
import { ApolloProvider } from '@apollo/client'
import client from './configureApolloClient'

import CmdK from 'components/CmdK'

import CurrentUserProvider from './currentUserContext'
import { ClippyProvider } from 'hooks/useClippy'

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import('@fortawesome/pro-regular-svg-icons').then(({ far }) => library.add(far))
import('@fortawesome/pro-solid-svg-icons').then(({ fas }) => library.add(fas))
import('@fortawesome/pro-light-svg-icons').then(({ fal }) => library.add(fal))
import('@fortawesome/free-brands-svg-icons').then(({ fab }) => library.add(fab))
dom.watch()

import moment from 'moment-timezone'
moment.tz.setDefault('UTC')
import 'moment.distance'

// import * as OfflinePluginRuntime from 'offline-plugin/runtime'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved */
// import '!file-loader?name=[name].[ext]!./images/favicon.ico'
/* eslint-enable import/no-unresolved */

import { HelmetProvider, Helmet } from 'react-helmet-async'

// Import i18n messages
import { translationMessages } from './i18n'

// Create redux store with history
const MOUNT_NODE = document.getElementById('app')

const ConnectedApp = Sentry.withProfiler((props) => (
	<Suspense fallback={null}>
		<BrowserRouter history={history}>
			<LanguageProvider messages={props.messages}>
				<ApolloProvider client={client}>
					<CurrentUserProvider>
						<ClippyProvider>
							<HelmetProvider>
								<Helmet titleTemplate="Acquire App | %s" defaultTitle="Acquire App" />
								<CmdK />
								<App />
							</HelmetProvider>
						</ClippyProvider>
					</CurrentUserProvider>
				</ApolloProvider>
			</LanguageProvider>
		</BrowserRouter>
	</Suspense>
))

ConnectedApp.propTypes = {
	messages: PropTypes.object,
}

const root = createRoot(MOUNT_NODE)

const render = (messages) => {
	root.render(<ConnectedApp messages={messages} />)
}

if (module.hot) {
	// Hot reloadable translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./i18n'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE)
		render(translationMessages)
	})
}

render(translationMessages)

// if (process.env.NODE_ENV === 'production') {
// 	if ('serviceWorker' in navigator) {
// 		window.addEventListener('load', () => {
// 			navigator.serviceWorker
// 				.register('/service-worker.js')
// 				.then((registration) => {
// 					console.log('SW registered: ', registration)
// 				})
// 				.catch((registrationError) => {
// 					console.log('SW registration failed: ', registrationError)
// 				})
// 		})
// 	}
// }

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
// 	OfflinePluginRuntime.install({
// 		onUpdating: () => {
// 			console.log('SW Event:', 'onUpdating')
// 		},
// 		onUpdateReady: () => {
// 			console.log('SW Event:', 'onUpdateReady')
// 			// Tells to new SW to take control immediately
// 			OfflinePluginRuntime.applyUpdate()
// 		},
// 		onUpdated: () => {
// 			console.log('SW Event:', 'onUpdated')
// 			// Reload the webpage to load into the new version
// 			window.location.reload()
// 		},

// 		onUpdateFailed: () => {
// 			console.log('SW Event:', 'onUpdateFailed')
// 		},
// 	})
// 	// require('offline-plugin/runtime').install() // eslint-disable-line global-require
// }
